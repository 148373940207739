<template>
  <div class="terms-of-service-wrap h-100">
    <div class="terms-of-service-center">
      <div class="terms-of-service-center-title">
        <h1 class="title flex cen-center container">{{ "VIP会员服务协议" }}</h1>
      </div>
    </div>
    <div class="terms-of-service-con container">
      <div>
        欢迎使用北京火星火翎文化科技有限公司（以下简称“Spark Music”）的VIP会员服务，为了保障您的权益，请在进行下一步操作前，详细阅读本协议的所有内容，特别是加粗部分。当您勾选”同意《VIP会员服务协议》或其他具有类似含义的按钮时，您的行为表示您同意并签署了本协议，并同意遵守本协议中的约定。该协议构成您与Spark Music达成的协议，具有法律效力。
        <p class="mt20">本协议是《Spark Music网络服务使用协议》的补充协议，是其不可分割的组成部分，与其构成统一整体。本协议与《Spark Music网络服务使用协议》存在冲突的，以本协议为准。本协议内容包括本协议正文、Spark Music已经发布和/或将来可能发布的与Spark Music VIP会员服务相关的各类规则。所有规则为本协议不可分割的组成部分，与本协议具有同等法律效力。一旦相关内容发生变动，Spark Music将会通过电子邮件或网站公告等方式提示您。如果您不同意对本协议内容所做的修改，则应立即停止使用本服务；如果您继续使用本服务的，则视为您同意对本协议内容所做的修改。</p>

        <p class="mt20 font-bold ft16">一. 定义</p>
        <p class="mt8">1.Spark Music VIP会员：指注册用户在签署本协议并根据Spark Music公布的收费标准支付相应的费用后获取的特殊资格，具体资格名称以Spark Music公示的名称为准。以下简称“会员”。</p>
        <p class="">
          2.会员服务：指会员所享有根据选择购买的会员选项所享有的增值服务，具体以Spark Music公布的服务内容为准。
        </p>
        <p class="">3.Spark Music：指https://www.spark.art/musicFine，主要为您提供正版音乐素材授权服务，由北京火星火翎文化科技有限公司所有及运营。</p>
        <p class="mt20 font-bold ft16">二. 使用规范</p>
        <p class="mt8">
          1.您申请开通会员服务时，可能需要提交您本人的手机号码、邮箱及第三方支付账户等个人资料或企业身份证明资料，并保证您提供的个人资料真实、准确、完整、合法有效，如有变动，您应及时更新。如您提供的资料不合法、不真实、不准确、不详尽，由此引起的损失及法律责任将由您自行承担，给Spark Music造成损失的，Spark Music保留要求您赔偿的权利。
        </p>
        <p class="mt8">
         2.Spark Music有权单方对其提供的虚拟产品(包括但不限于VIP会员)予以定价，并有权根据自身运营需要予以变更，且Spark Music在此获得您的授权可以根据平台统一调整比例调整您已经获取的虚拟产品;
        </p>
        <p class="mt8">
         基于虚拟产品的性质和特征，您所获取的虚拟产品不能够进行退货、换货、兑换现金亦不得用于商业领域如买卖、置换、抵押等。一切通过非官方公布渠道取得的虚拟产品及其衍生服务均不获保护，Spark Music有权中止或终止对您的服务。
        </p>
        <p class="mt8">
         3.会员服务有效期根据您选择购买的会员选项的具体时长为准，自您成功支付会员服务费用后视为您已使用，Spark Music概不退款。若您希望在有效期届满后继续享受会员服务，则需要重新购买。
        </p>
        <p class="mt8">
         4.若您购买了多个收费服务，其服务范围相同且有效期发生重合的情况下，高等级套餐会自动覆盖低等级套餐，同时您已支付的低等级套餐费用Spark Music概不退款。例如您在8月1日购买企业月度VIP套餐399/月，8月7日购买企业年度VIP套餐3999元/年，企业年度VIP将自动覆盖企业月度VIP套餐。
        </p>
        <p class="mt8">
         5.在使用会员服务过程中，您应当是具备与您行为相适应的民事行为能力的自然人、法人或其他组织。若您不具备前述主体资格，请务必在您的监护人的帮助下共同完成，若因此使得Spark Music或任何第三方权益受损，您及您的监护人将承担相应的责任。
        </p>
        <p class="mt8">
         6.您知悉并同意，Spark Music有权通过邮件、短信或电话等形式， 向您发送会员活动相关信息。
        </p>
        <p class="mt8">
         7.您确认会员服务仅限您本人使用，同时您保证您将合理使用会员服务，不利用会员服务非法获利，不以任何形式转让您所享有的会员服务，不以任何形式将您所享有的会员服务借给他人使用，如Spark Music有合理理由怀疑您存在不当使用会员服务时，Spark Music有权取消您的会员资格且不退还您支付的会员服务费用，因此产生的相关责任及损失均由您自行承担，给Spark Music造成损失的，Spark Music保留向您追偿的权利。
        </p>
        <p class="mt8">
         8.Spark Music保留在法律法规允许的范围内自行决定是否接受您的会员申请、调整会员服务内容、取消会员资格等相关权利。
        </p>
        <p class="mt8">
         9.您理解并保证，您在使用会员服务过程中遵守诚实信用原则。如Spark Music发现或有合理理甶怀疑您存在以下任一情形的：
        </p>
        <p class="">（a) 通过任何不当手段或以违反诚实信用原则的方式开通会员服务的，包括但不限于通过恶意软件绕过Spark Music设定的正常流程开通会员服务；</p>
        <p class="">（b) 您提供的资料不合法、不真实，包括但不限于盗用他人信息；</p>
        <p class="">（c) 您通过Spark Music会员专区购买的商品用于法律法规所禁止的目的的；</p>
        <p class="">（d) Spark Music有合理理由怀疑您存在违反诚实信用原则的其他行为。 则Spark Music有权拒绝您的会员服务开通需求；若已开通的，Spark Music有权单方面取消您的会员资格且不退还您支付的会员服务费用。</p>

        <p class="mt8">
         10.您理解并同意Spark Music有权根据实际业务需要不时地调整其网站内容而无需另行通知您，包括但不限于内容增减、授权期限、收费模式等等。
        </p>
        <p class="mt8">
         11.您应该通过Spark Music指定的包括但不限于支付宝、微信支付等现有或未来新增的方式依约支付一定费用后开通本服务。本服务开通之后，适用“一人一账号”原则，各个账号之间的使用权限不得更改或转移、互赠、售卖、租借、分享给任何第三人，各账号使用权限有且仅有开通该账号的用户享有，否则，Spark Music有权对该账号进行封号、中止或终止对您的服务。请您在登录/充值/参加活动时注意区分，避免造成损失。若造成损失的，由您自行承担。
        </p>
        <p class="mt8">
         12.您应及时完成在线实名或企业认证，如您拥有多个不同主体账号，多个主体账号均需完成认证，并且不同主体账号如需使用相同音乐版权素材，应当分别进行下载，未经下载或未获得Spark Music特别授权，该使用行为将无法获得授权。如因此造成的损失，Spark Music不予承担。
        </p>
        <p class="mt8">
         13.若您选择开通Spark Music的VIP服务，您须根据会员服务规则使用音乐版权素材，并在规定的套餐有效期内使用下载权限，有效期届满后未使用部分视为您自动放弃，不得以此为由向Spark Music申请退款。
        </p>
        <p class="mt20 font-bold ft16">三. 会员服务售后</p>
        <p class="mt8">
          1.您知悉并确认，开通会员服务后，若您中途主动取消服务或终止资格或被Spark Music根据《Spark Music网络服务使用协议》、本协议及相关规则注销账号、终止会员资格的，您已支付的会员服务费用将不予退还。
        </p>
        <p class="mt8">
          2.如您有其他与会员服务售后相关的问题，可以通过Spark Music公布的联系方式联系客服进行反馈。
        </p>
        <p class="mt20 font-bold ft16">四.七天保价</p>
        <p class="mt8">
          1.Spark Music用户可享受保价承诺，Spark Music承诺：自通过Spark Music官方充值渠道购买会员之日起7天内，如遇Spark Music官方充值渠道中同会员销售价格下调，Spark Music将退还差价。
        </p>
        <p class="mt8">
          2.“同会员”指：会员有效期相同、会员类目相同、会员所提供服务相同、会员所购买渠道相同。
        </p>
        <p class="mt8">
          3.“Spark Music官方充值渠道”指：通过“https://www.spark.art/subscribe”链接充值开通Spark Music会员的渠道
        </p>
        <p class="mt8">
          4.“销售价格”指：Spark Music VIP官方充值渠道中展示的会员实时售价。
        </p>
        <p class="mt8">
          5.若会员用户自会员购买之日起7天内，发现同会员销售价格下调，可立即联系Spark Music客服，并提供相关会员链接信息或截图。
        </p>
        <p class="mt8">
          6.若经核实通过、Spark Music会将差价在7个工作日内通过支付渠道原路退还。
        </p>
        <p class="mt8">
          7.除官方充值渠道外的特殊活动会员（包括但不限于Spark Music“拼团““秒杀”活动，或第三方充值渠道）不参与本保价政策，亦不可作为差价退还的参照标准。
        </p>
        <p class="mt20 font-bold ft16">五. 其他约定</p>
        <p class="mt8">
          1.通知：所有发给您的通知都可通过电子邮件、常规的信件或在网站显著位置公告的方式进行传送。
        </p>
        <p class="mt8">
          2.本协议适用中华人民共和国的法律。当本协议的任何内容与中华人民共和国法律相抵触时，应当以法律规定为准，同时相关条款将按法律规定进行修改或重新解释，而本协议其他部分的法律效力不变。
        </p>
        <p class="mt8">
          3.如使用会员服务过程中出现纠纷，您与Spark Music应友好协商解决，若协商不成，应约定相关诉讼由北京市朝阳区人民法院管辖。
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  components: {},

  NgImagecreated() {},

  methods: {
    handleHelpTab(e) {
      console.log("e: ", e);
      this.activeTab = e;
      this.activeCollapse = [];
    },
    changeTab() {
      this.activeCollapse = [];
    },
    changeActivekey(e) {
      console.log("e: ", e);
    },
    handleClick(event) {
      // If you don't want click extra trigger collapse, you can prevent this:
      event.stopPropagation();
    },
  },
};
</script>

<style lang="scss" scope>
.terms-of-service-wrap {
  p {
    margin-bottom: 0;
  }
  .mt8 {
    margin-top: 0.8rem;
  }
  .mt20 {
    margin-top: 2rem;
  }
  color: rgba(0, 0, 0, 0.85);
  font-size: 1.4rem;
  width: 100%;
  // height: 8.8rem;

  .terms-of-service-center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 8.8rem;
    margin-bottom: 3.2rem;
    background: rgba(0, 0, 0, 0.02);

    .terms-of-service-center-title {
      .title {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 765px;
        // margin: 0 auto;
        
        font-size: 3.2rem;
      }
    }
  }
  .terms-of-service-con {
    width: 765px;
    margin-top: 3.2rem 0;
    // padding-bottom: 4rem;
  }
}

// mobile
@media screen and (max-width: $screen-width-md) {
  .terms-of-service-wrap {
    width: 100%;
    // height: 12.8rem;

    // padding-bottom: 3.2rem;

    .container {
      margin: 0;
    }
    .terms-of-service-center {
      width: 100%;
      height: 12.8rem;
      padding: 0 1rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 12.8rem;
      box-sizing: border-box;
      .terms-of-service-center-title {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .title {
          // margin: 0;
          width: 100%;
          font-size: 2.8rem;
        }
      }
    }
    .terms-of-service-con {
      width: 100%;
      padding: 0 1rem;
      box-sizing: border-box;
    }
  }
}

// pc
@media screen and (min-width: $screen-width-md) {
}
</style>
